// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { graphql } from 'gatsby'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// import Carousel from 'antd/lib/carousel'
// import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

// import ArrowLeft from '../components/svg/arrow-left'
// import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'
import website from '../seo/website.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Buy Or Contribute',
  slug: '/buy-or-contribute',
  abstract:
    "The author and the publisher of this book have chosen to offer this book, in it's entirety, to read online as a web-book. This is not for free, but available on a Gift-Economy and Pay-What-Feels-Right basis. We want you to enjoy the book, learn from the book, but also support the book and this format of open publishing. If it was worth your time, please consider contributing or buying the print version of this book.",
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Buy Or Contribute', slug: '/buy-or-contribute' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     img1: file(relativePath: { eq: "book/img1.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img2: file(relativePath: { eq: "book/img2.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img3: file(relativePath: { eq: "book/img3.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img4: file(relativePath: { eq: "book/img4.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img5: file(relativePath: { eq: "book/img5.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img6: file(relativePath: { eq: "book/img6.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img7: file(relativePath: { eq: "book/img7.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const x = true
  // const resources = [
  //   { img: props.data.img1 },
  //   { img: props.data.img2 },
  //   { img: props.data.img3 },
  //   { img: props.data.img4 },
  //   { img: props.data.img5 },
  //   { img: props.data.img6 },
  //   { img: props.data.img7 },
  // ]

  // const sliderConf = {
  //   infinite: false,
  //   slidesToScroll: 1,
  //   dots: true,
  //   arrows: true,
  //   prevArrow: <ArrowLeft />,
  //   nextArrow: <ArrowRight />,
  //   customPaging: (i) => {
  //     return (
  //       <a aria-label={`Go to slide ${i + 1}`} role="link">
  //         <GatsbyImage image={getImage(resources[i].img)} />
  //       </a>
  //     )
  //   },
  //   dotPosition: 'bottom',
  // }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>Support open publishing</h1>
      <p className="dropcap">
        <span>T</span>he author and the publisher of this offering have chosen
        to offer it, in it's entirety, to read online as a web-book. This is not
        for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. We want you to enjoy the project, learn from it, but also support
        the it and this format of open publishing. If it was worth your time,
        please consider contributing or buying the print version of this
        offering.
      </p>
      <h2>Buy the print version of the offering</h2>
      <p>
        <Link to={website.shopUrl}>
          Please follow this link to buy the print version.
        </Link>
      </p>
      <h2>Contribute directly</h2>
      <p>
        <Link
          to={`mailto:prisma@auroville.org.in?subject=${encodeURIComponent(
            `re: contribution for the web-book ${about.altTitle}`
          )}&body=${encodeURIComponent(
            `Hello! I would like to contribute ₹1000 for the web-book ${about.altTitle}. Please send me further details. Thank you.`
          )}`}
        >
          Please write to PRISMA Books a letter indicating your intent to
          contribute and they will share further instructions.
        </Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
